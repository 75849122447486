<template>
  <div class="detail" v-loading="loading">
    <div class="order-activity">
      <el-steps :active="order.basic.status" align-center>
        <el-step title="买家下单"
                 :description="new Date(order.basic.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')"></el-step>
        <el-step v-for="(step, idx) in steps" :title="step.title" :description="step.description" :key="idx"></el-step>
      </el-steps>
    </div>
    <div class="order-info">
      <div class="order-info-container">
        <div class="order-user-info">
          <p><label>订单编号:</label> <span>{{order.basic.orderSn}}</span></p>

          <p><label>买家:</label> <span>{{order.user.niceName}}</span></p>
          <p><label>会员等级:</label> <span>{{order.user.vipName}}</span></p>
        </div>
        <div class="order-detail-info">
          <p><label>订单状态:</label><b class="status" :class="status[order.basic.status]">{{order.basic.status |
            filterStatus}}</b></p>
          <p>
            <label>优惠劵:</label>
            <template v-if="order.tickets">
              <el-tag v-for="ticket in order.tickets" :key="ticket.name">{{ticket.name}}</el-tag>
            </template>
            <span v-if="!order.tickets || order.tickets.length ===0">暂无优惠</span>

          </p>
          <p><label>付款方式:</label> <span>{{order.basic.payChanel | filterPay }}</span></p>
        </div>
      </div>
      <p class="order-container-title">商品信息</p>
      <div class="goods">
        <div class="goods-title">
          <div class="goods-info">商品标题</div>
          <div class="goods-price">单价/数量</div>
          <div class="goods-person">成本价</div>
          <div class="goods-person">优惠金额</div>
          <div class="goods-operate">制作进度</div>
          <div class="goods-operate">操作</div>
        </div>
        <div class="goods-container">
          <div class="goods-item" v-for="goods  in order.goods" :key="goods.uuid">
            <div class="goods-info">
              <div class="goods-info-item">

                <img :src="goods.goodsThumb" class="goods-img">
                <div class="goods-content">
                  <div class="goods-content-title">
                    <span class="name"> {{goods.goodsName}}</span><br>
                    <span class="number"> {{goods.goodsParam}}</span>
                  </div>
                  <div class="goods-content-price">
                    ￥{{((goods.goodsPrice * goods.goodsNumber) / 100) | F1000}}<br>
                    <span class="number"> x{{goods.goodsNumber}} </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="goods-text goods-price">￥{{(goods.goodsPrice / 100) | F1000}}/ {{goods.goodsNumber}}杯</div>
            <div class="goods-text goods-person"> ￥{{(goods.goodsCostPrice / 100) | F1000}}</div>
            <div class="goods-text goods-person"> ￥{{(goods.promotionAmount / 100) | F1000}}</div>
            <div class="goods-text goods-person" style="padding: 0 8px;">
              <el-progress
                style="margin: 4px auto"
                :color="$themeColor"
                :percentage="(goods.sendNumber * 100) / goods.goodsNumber"
                type="circle" :width="36" :show-text="false"></el-progress>
              <span style="font-size: 12px; margin-top: 4px">已制作：{{goods.sendNumber}}</span>
              <span style="font-size: 12px; margin-top: 2px;margin-bottom: 2px;">剩余：{{goods.goodsNumber - goods.sendNumber}}</span>
            </div>
            <div class="goods-text goods-operate">
              <el-popover
                v-model="goods.refund.show"
                placement="left"
                width="400"
                trigger="manual">
                <el-form label-width="80px">
                  <el-form-item label="退货信息">
                   <p class="page-title" style="line-height: 40px"> {{goods.refund.name}}</p>
                  </el-form-item>
                  <el-form-item label="退货数量">
                    <el-input v-model.number="goods.refund.number" type="number"
                              @change="goods.refund.money = (goods.goodsPrice * goods.refund.number) / 100" ></el-input>
                  </el-form-item>
                  <el-form-item label="退款金额">
                    <el-input v-model.number="goods.refund.money" type="number" :max="goods.payAmount / 100">
                      <span slot="append">元</span>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <p class="yk-tip">如果商品退款数量大于或等于订单总数,订单自动关闭</p>
                  </el-form-item>
                  <el-form-item >
                    <el-button type="primary" @click="handleRefund(goods)">确认退款</el-button>
                    <el-button type="primary" @click="goods.refund.show=false" plain>取消</el-button>
                  </el-form-item>
                </el-form>
                <el-button type="text"
                           :disabled="order.basic.status < 2"
                           slot="reference" @click="goods.refund.show = true">退款</el-button>
              </el-popover>

            </div>
          </div>
        </div>
        <div class="goods-bill">
          <p><label>商品小记: </label><span class="total-money">{{order.basic.goodsAmount}}</span></p>
          <p><label>合计金额: </label><span class="total-money">{{(order.basic.totalAmount / 100) | F1000}}</span></p>
          <p><label>优惠券优惠: </label><span class="reduce-money">-￥{{(order.basic.promotionAmount / 100) | F1000}}</span>
          </p>
          <p><label>实付款: </label><span class="pay-money">￥{{(order.basic.payAmount / 100) | F1000}}</span></p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: '',
    components: {},
    props: {
      orderSn: String
    },
    computed: {
      ...mapGetters(['prove']),
    },
    mounted: function () {
      if (this.orderSn) {
        this.loadOrder(this.orderSn)
      }
    },
    watch: {
      orderSn: function (v) {
        this.loadOrder(v)
      }
    },
    data: function () {
      return {
        loading: false,
        order: {
          basic: {},
          user: {},

          tickets: [],
          goods: [],
        },
        steps: [],
        status: ['', 'unpaid', 'unsend', 'unrecv', '', 'finish']
      }
    },
    methods: {
      loadOrder(orderSn) {
        this.$api('mkt.order.detail', {orderSn: orderSn}).then(data => {
          this.order.basic = data.basic
          switch (this.order.basic.status) {
            case 1:
              this.steps = [
                {title: '买家付款', description: '等待付款'},
                {title: '制作中', description: '等待付款'},
                {title: '制作完成', description: '等待完成'},
              ]
              break;
            case 2:
            case 3:
            case 4:
              this.steps = [
                {title: '买家付款', description:  new Date(this.order.basic.payTime * 1000).Format('yyyy-MM-dd hh:mm:ss')},
                {title: '制作中', description: '正在进行'},
                {title: '制作完成', description: '等待完成'},
              ]
              break;

            case 5:
              this.steps = [
                {title: '买家付款', description:  new Date(this.order.basic.payTime * 1000).Format('yyyy-MM-dd hh:mm:ss')},
                {title: '制作中', description: '已完成'},
                {title: '制作完成', description: new Date(this.order.basic.finishTimeAt * 1000).Format('yyyy-MM-dd hh:mm:ss')},
              ]
              break;

          }

          this.order.user = data.user
          let goods = data.goods || []
          goods = goods.map(g => {
            return {
              ...g,
              refund: {
                show: false,
                uuid: g.uuid,
                name: g.goodsName,
                number: g.goodsNumber - g.sendNumber,
                money: ((g.goodsNumber - g.sendNumber) * g.goodsPrice) / 100,
              }
            }
          })

          this.order.goods = goods
        })
      },
      handleRefund(goods) {
        this.$api('mkt.order.refund', {}, {
          uuid: goods.uuid,
          orderSn: this.orderSn,
          proveName: this.prove.name,
          number: goods.refund.number,
          price: goods.refund.money * 100,
        }).then(() => {
          this.$message.success('退款申请已发起，等待成功')
          goods.sendNumber += goods.refund.number
          goods.refund.show = false
        })
      }
    },
  }
</script>

<style lang="scss">
  .detail {
    border-radius: 5px;
    padding: 24px 0;
    height: 100%;

    label {
      display: inline-block;
      font-size: 12px;
      color: #999;
      width: 80px;
    }

    .order-activity {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .finish {
      color: $theme_color;
    }

    .order-info {
      width: 80%;
      margin: auto;
      padding-bottom: 20px;

      .order-info-container {
        display: inline-flex;
        margin: auto;
        width: 100%;
        font-size: 12px;
        border: 1px solid #f2f2f2;

        .order-user-info {
          border-right: 1px solid #f2f2f2;
        }

        .order-user-info, .order-detail-info {
          flex: 1;
          padding: 30px 20px;
        }

        .status {
          font-size: 24px;
        }

        p {
          margin-bottom: 15px;
        }
      }

      .order-container-title {
        font-weight: 700;
        padding: 20px 0;
        color: $theme_color;
      }

      .goods {
        .goods-info {
          width: 50%;
          text-align: left;
          padding-left: 20px;
        }

        .goods-price {
          width: 20%;
        }

        .goods-person {
          width: 15%;
        }

        .goods-operate {
          width: 15%;
        }

        border: 1px solid #f2f2f2;

        .goods-title {
          padding: 10px 0;
          border-bottom: 1px solid #f2f2f2;
          text-align: center;
          display: inline-flex;
          width: 100%;

        }

        .goods-container {
          .goods-item {
            width: 100%;
            border-bottom: 1px solid #f2f2f2;
            display: inline-flex;

            .goods-info-item {
              width: 100%;
              display: inline-flex;
              padding: 20px 15px 0 15px;

              .goods-img {
                width: 70px;
                height: 70px;
                border: 1px solid #efefef;
                padding: 1px;
              }

              .goods-content {
                -webkit-box-flex: 1;
                -webkit-flex: 1;
                -ms-flex: 1;
                flex: 1;
                margin-left: 10px;
                text-align: left;
                display: flex;
                align-items: center;

                .number {
                  font-size: 12px;
                  color: #999;
                }

                .goods-content-title {
                  flex: 1;
                  line-height: 1.5;

                  .name {
                    color: #333333;
                  }

                }

                .goods-content-price {
                  float: right;
                  text-align: right;
                  display: inline-block;
                  line-height: 1.5;
                  width: 130px;
                }
              }
            }

            .goods-text {
              flex-direction: column;
              -webkit-justify-content: center;
              justify-content: center;
              display: flex;
              text-align: center;

              p {
                margin-bottom: 10px;
              }
            }

            .goods-info, .goods-price, .goods-person {
              border-right: 1px solid #f2f2f2;
            }

          }

          .goods-info-item:last-child {
            padding-bottom: 20px;
          }

        }

        .goods-bill {
          padding: 10px 20px;

          p {
            text-align: right;
            padding: 5px 0;

            span {
              margin-left: 15px;
            }

            .total-money {
              color: $theme_color;
            }

            .reduce-money {
              color: #F95E5A;
              font-weight: 500;
              font-size: 14px;
            }

            .pay-money {
              color: #FF3B30;
              font-weight: bold;
              font-size: 16px;
            }
          }
        }
      }

    }

  }
</style>
