<template>
  <div class="order">
    <el-row class="order-filter">
      <el-form label-width="auto" size="mini">
        <el-col :span="14">
          <el-form-item label="订单搜索:">
            <div class="order-filter-search">
              <el-select v-model="page.nameType">
                <el-option v-for="item in uuidTypes"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>

              <el-input placeholder="请输入订单编号" class="order-input-filter" clearable
                        v-model="page.name"
                        prefix-icon="el-icon-search"></el-input>

              <el-button type="primary" size="small" @click="loadOrder">筛选</el-button>
              <el-button type="text" size="small" @click="showFilter=!showFilter">{{showFilter? '收起': '更多'}}筛选
              </el-button>
            </div>

          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="制作时间:">
            <time-range @change="handleMakeTime"/>
          </el-form-item>
        </el-col>

        <template v-if="showFilter">
          <el-col :span="8">
            <el-form-item label="来源渠道:">
              <el-select placeholder="请选择来源渠道" v-model="page.formChanel" class="order-input-filter"
                         @change="loadOrder">
                <el-option v-for="item in fromTypeOption"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="付款状态:">
              <el-select placeholder="请选择订单付款状态" class="order-input-filter" v-model="page.orderType"
                         @change="loadOrder">
                <el-option v-for="item in orderTypeOption"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="订单状态:">
              <el-select placeholder="请选择订单状态" v-model="page.status" class="order-input-filter"
                         @change="loadOrder">
                <el-option v-for="item in statusOption"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="支付渠道:">
              <el-select placeholder="请选择支付渠道" v-model="page.payChanel" class="order-input-filter"
                         @change="loadOrder">
                <el-option v-for="item in payChanelOption"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="支付类型:">
              <el-select placeholder="请选择支付类型" v-model="page.payChanelType" class="order-input-filter"
                         @change="loadOrder">
                <el-option v-for="item in payChanelTypeOption"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </template>


      </el-form>
    </el-row>

    <div>
      <div class="goods-title">
        <div class="goods-info">商品</div>
        <div class="goods-price">订单金额/合计数量</div>
        <div class="goods-person">支付方式</div>
        <div class="goods-operate" v-if="showDetailSw">操作</div>
        <div class="goods-status">状态</div>
      </div>

      <div class="order-item" v-for="order in orders" :key="order.orderSn" @click="showDetail(order.orderSn)">
        <div class="order-item-top">
          <div class="order-item-top-info">
            <span class="min_program">{{order.source | filterSource}}</span>
            <span>订单编号:  {{order.orderSn}}</span>
          </div>
          <div>
            <span>下单时间: {{new Date(order.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</span>
          </div>
        </div>
        <div class="order-item-content">
          <div class="goods-info">
            <template v-for="(goods, idx) in order.goods">
              <div class="goods-info-item" v-if="idx <2" :key="goods.uuid" >

                  <img :src="goods.thumb" class="goods-img">
                  <div class="goods-content">
                    <div class="goods-content-title">
                      <span class="name"> {{goods.name}}</span><br>
                      <span class="number"> 数量</span>
                    </div>
                    <div class="goods-content-price">
                      ￥{{((goods.price * goods.number) / 100) | F1000}}<br>
                      <span class="number"> x{{goods.number}} </span>
                    </div>
                  </div>
              </div>
            </template>
            <div class="yk-tip" style="margin-top:4px;margin-bottom: 4px; margin-left: 16px; " v-if="order.goods.length > 2">剩余{{order.goods.length - 2}}种商品未展示,可点击查看详情</div>
          </div>
          <div class="goods-price goods-text">
            ￥{{(order.totalAmount / 100) | F1000}} / {{order.goodsAmount}}
          </div>
          <div class="goods-person goods-text">
            <p>
               <span :class="[
                             { wechatPay: order.payChanel === 2 },
                                    { aliPay: order.payChanel === 3 }]">{{order.payChanel | filterPay }}</span>
            </p>
          </div>
          <div class="goods-operate goods-text" v-if="showDetailSw">
            <el-button type="text">查看详情</el-button>
          </div>
          <div class="goods-status goods-text finish">
            {{order.status | filterStatus}}
          </div>
        </div>
      </div>

      <el-pagination
        @current-change="handleCurrentChange"
        class="pagination"
        :current-page="page.curPage"
        :page-size="page.limit"
        layout="total, prev, pager, next, jumper"
        :total="page.total">
      </el-pagination>
    </div>

    <el-drawer
      title="订单详情"
      class="order"
      :visible.sync="showOrder">
      <shop-detail :orderSn="orderSn"></shop-detail>
    </el-drawer>
  </div>

</template>

<script>

  import ShopDetail from './shop-detail'
  export default {
    name: '',
    props: {
      pagination: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    components: {
      ShopDetail
    },
    mounted: function () {
      this.loadOrder()
      this.showDetailSw = this.$btnCheck('order_query_shopDetail')
    },
    data: function () {
      return {
        orders: [],
        showFilter: false,
        showOrder: false,
        showDetailSw: false,
        uuid: '',
        orderSn: '',
        fromTypeOption: [
          {label: '全部渠道', value: ''},
          {label: '支付页面', value: 'H5'},
          {label: '微信小程序', value: 'wechat'},
          {label: '支付宝小程序', value: 'AliMini'},
        ],
        orderTypeOption: [
          {label: '所有订单', value: 0},
          {label: '待付款', value: 1},
          {label: '待收货', value: 2},
          {label: '已完成', value: 3},
        ],
        statusOption: [
          {label: '全部', value: 0},
          {label: '未删除', value: 1},
          {label: '已删除', value: 2},
        ],
        payChanelOption: [
          {label: '所有支付', value: 0},
          {label: '无支付', value: 1},
          {label: '微信支付', value: 2},
          {label: '支付宝', value: 3},
        ],
        payChanelTypeOption: [
          {label: '所有类型', value: 0},
          {label: '微信子商户支付', value: 1},
          {label: '咖博士微信支付', value: 2},
          {label: '微信商户支付', value: 3},
          {label: '咖博士支付宝', value: 4},
          {label: '支付宝支付', value: 5},
        ],
        uuidTypes: [
          {label: '订单编号', value: 0},
        ],
        makeTimeRange: [],
        loading: false,
        page: {
          current: 0,
          total: 0,
          start: 0,
          limit: 10,
          startAt: 0,
          endAt: 0,
          nameType: 0,
          name: '',
          payChanel: 0,
          payChanelType: 0,
          fromChanel: '',
          status: 0,
          payStatus: 0,
          orderType: 0,
        }
      }
    },
    filters: {
      filterSource(source) {
        switch (source) {
          case 'wechat':
            return "微信小程序"
         default:
           return "未知"
        }
      },
    },
    methods: {
      handleMakeTime(ts) {
        this.page.startAt = ts[0]
        this.page.endAt = ts[1]
        this.loadOrder()
      },

      handleCurrentChange(value) {
        this.page.curPage = value
        this.page.start = (value - 1) * this.page.limit
        this.$api('mkt.order.shopList', {...this.pagination, ...this.page}).then(data => {
          this.orders = data || []
        })
      },
      showDetail (orderSn) {
        this.orderSn = orderSn
        this.showOrder = true
      },

      loadOrder() {
        let vue = this

        if (!this.pagination.shopUuid) {
          let idx =  this.uuidTypes.findIndex(item => {return item.label === '店铺编号'})
          if (idx === -1) {
            this.uuidTypes.push( {label: '店铺编号', value: 1})
          }
        }
        this.$api('mkt.order.shopUnion', {...this.pagination, ...this.page}).then(data => {
          vue.orders = data.list || []
          vue.page.total = parseInt(data.total)
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .order {
    &-filter {
      margin: $large-space $middle-space;

      &-search {
        display: flex;
        align-items: center;

        .el-input {
          margin-left: $small-space;
          margin-right: $middle-space;
        }
      }
    }

    .goods-info {
      width: 40%;
      text-align: left;
    }

    .goods-price {
      width: 10%;
    }

    .goods-person {
      width: 20%;
    }

    .goods-operate {
      width: 20%;
    }

    .goods-status {
      width: 10%;
    }

    .order-item {
      margin-top: 10px;
      border: 1px solid #e5e5e5;
      cursor: pointer;

      .order-item-top {
        padding: 16px 20px;
        background: #f7f7f7;
        font-size: 12px;
        display: inline-flex;
        justify-content: center;
        width: 100%;

        .order-item-top-info {
          flex: 1;
        }

        .min_program {
          padding: 3px 8px;
          margin-right: 15px;
          border: 1px solid $theme_color;
          color: $theme_color;
        }
      }
    }

    .order-item:hover {
      border-color: $theme-light-2;

      .order-item-top {
        background-color: $theme-light-2;

        .min_program {
          border-color: white;
        }

        * {
          color: white;
        }
      }
    }

    .order-item-content {
      display: inline-flex;
      width: 100%;

      .goods-info-item {
        width: 100%;
        padding: 20px 15px 0 15px;
        display: inline-flex;

        .goods-img {
          width: 70px;
          height: 70px;
          border: 1px solid #efefef;
          padding: 1px;
        }

        .goods-content {
          -webkit-box-flex: 1;
          -webkit-flex: 1;
          -ms-flex: 1;
          flex: 1;
          margin-left: 10px;
          text-align: left;
          display: flex;
          align-items: center;

          .number {
            font-size: 12px;
            color: #999;
          }

          .goods-content-title {
            flex: 1;
            line-height: 1.5;

            .name {
              color: #333333;
            }

          }

          .goods-content-price {
            float: right;
            text-align: right;
            display: inline-block;
            line-height: 1.5;
            width: 130px;
          }
        }
      }

      .goods-info-item:last-child {
        padding-bottom: 20px;
      }

      .goods-text {
        flex-direction: column;
        -webkit-justify-content: center;
        justify-content: center;
        display: flex;
        text-align: center;

        p {
          margin-bottom: 10px;
        }
      }

      .goods-info, .goods-price, .goods-person, .goods-operate {
        border-right: 1px solid #f2f2f2;
      }

      .goods-operate {
        font-size: 12px;

        p {
          cursor: pointer;
          color: $theme_color;
        }
      }
    }

    .finish {
      color: $theme_color;
    }

    .wechatPay {
      color: #44b549;
    }

    .aliPay {
      color: #00aaee;
    }

    .goods-title {
      display: inline-flex;
      padding: 15px 10px;
      background-color: #f1f5ff;
      width: 100%;
      text-align: center;

      * {
        color: #666;
      }

    }

    .order {
      ::v-deep .el-drawer {
        min-width: 1200px;
        padding: 0 $container-padding;
        padding-bottom: $middle-space;

        .el-drawer__header {
          padding-left: 0;
          margin-bottom: $middle-space;
          font-size: 18px;
          color: $color-black;
        }

        .el-drawer__body {
          height: 100%;
          overflow-y: scroll;
        }
      }

    }


  }
</style>
